<template>
  <div class="headComWrapper">
    <div class="title">
      <img :src="titleImg" alt="" />
    </div>
    <div class="info">
      <div class="music item" :class="{stopmusic:!isplay}" @click.stop='plays'>
        <audio v-show="false"  ref="audio" loop controls>
            <source :src="audioSrc">
            您的浏览器不支持这种格式的音乐
          </audio>
      </div>
      <!-- <div class="link item">
        <div class="linkInfo">
          <div class="titleInfo">关注我们</div>
          <ul class="iconWrapper">
            <li>
              <img src="../../assets/homeImg/header/bilibli.png" alt="">
               <span>哔哩哔哩</span>
            </li>
            <li> 
              <img src="../../assets/homeImg/header/wx.png" alt="">
               <span>微信公众号</span>
              </li>
            
            <li> 
              <img src="../../assets/homeImg/header/steam.png" alt="">
               <span>steam</span>
              </li>
          </ul>
          <div class="qqWrapper">
            <span>搜索：装甲红锋</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      titleImg: require("@/assets/homeImg/logo.png"),
      audioSrc:'https://tankdownload.golfpai.com/tankIndex/audio/HJ_YY_3~1.mp3',
      isplay:false
    };
  },
  computed: {},
  created() {},
  mounted() {
  },
  methods: {
    plays(){
      console.log(444)
      this.isplay=!this.isplay
    
      if(this.isplay){
          this.$refs.audio.play()
      }else{
  this.$refs.audio.pause()
      }
    }
  },
};
</script>

<style scoped lang="scss">
.headComWrapper {
  width: 100%;
  height: 100%;
  //   border: 1px solid red;
  display: flex;
  position: relative;
 
  .title {
    width: 215px + 74px + 20px;
    height: 100%;
    // border: 1px solid #000;
    margin-left: 1.5%;
    margin-top: 1.5%;
    img {
     // width: 100%;
      height: 100%;
    }
  }
  .info {
    width: 50px;
    height: 100%;
    display: flex;
    position: absolute;
    // border: 1px solid red;
    right: 30px;
    top: 30px;
    justify-content: space-between;
    cursor: pointer;
    .music {
      background: url("../../assets/homeImg/音乐_正常.png");
      background-size: 100% 100%;
    }
    .music:hover {
      background: url("../../assets/homeImg/音乐_滑过.png");
      background-size: 100% 100%;
    }
    .music:active {
      background: url("../../assets/homeImg/音乐_按下.png");
      background-size: 100% 100%;
    }
    .stopmusic{
      background: url("../../assets/homeImg/静音_正常.png");
      background-size: 100% 100%;
    }
    .stopmusic:hover{
      background: url("../../assets/homeImg/静音_选中.png");
      background-size: 100% 100%;
    }
    .link {
      background: url("../../assets/homeImg/分享_正常.png");
      background-size: 100% 100%;
    }
    .link:hover {
      background: url("../../assets/homeImg/分享_滑过.png");
      background-size: 100% 100%;
    }
    .link:active {
      background: url("../../assets/homeImg/分享_按下.png");
      background-size: 100% 100%;
    }
    .item {
      width: 40px;
      height: 40px;
      // border: 1px solid #fff;
     
      li{
 text-align:center;
      }
      img {
        width: 80%;
      }
    }
    .linkInfo {
      position: absolute;
      right: -1px;
      top: 40px;
      width: 220px;
      height: 130px;
      // border: 1px solid #8b8383;
      background:url('../../assets/homeImg/header/topdialog.png') no-repeat;
      background-size:100% 100%;
      color:#fff;
      padding: 10px;
      visibility: hidden;
      .titleInfo {
        font-size: 14px;
        font-weight: 600;
      }
      .iconWrapper {
        // height: 40px;
        margin:10px 0;
        width: 100%;
        // border: 1px solid #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size:12px;
        li {
          width:33%;
          height: 40px;
          border:1px solid #000;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            width:20px;
            margin-bottom:10px;
          }
        }
      }
      .qqWrapper {
        // margin-top: 10px;
        text-align:center;
        span {
          font-size: 20px;
          font-weight: 600;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          li {
            font-size: 12px;
            font-weight: 300;
            margin-left: 5px;
          }
        }
      }
    }
    .link:hover {
      .linkInfo {
        visibility: visible;
      }
    }
  }
}
</style>
